import React, { Component } from "react";
import { Table } from "reactstrap";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { connect } from "react-redux";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

class UserListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: undefined,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.props.changeUserColorPexeso(
      this.props.pexesoInfo.game.id,
      this.state.color
    );
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };
  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `${
            this.state.color ?? this.props.userInfo.color ?? "red"
          }`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <Table responsive>
        <tbody>
          {this.props.pexesoInfo.game.users.map((item) => (
            <tr key={item.userName}>
              <td>{item.userName}</td>
              <td>
                {item.userName === this.props.userInfo.userName ? (
                  <div>
                    <div style={styles.swatch} onClick={this.handleClick}>
                      <div style={styles.color} />
                    </div>
                    {this.state.displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker
                          color={this.state.color}
                          onChange={this.handleChange}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: item.color ?? "red",
                    }}
                    className={"pexesoUserColor"}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(UserListComponent);
