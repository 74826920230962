import { initialState } from "./store";

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "reset": {
      return {
        user: { ...initialState.user },
      };
    }
    case "errorMessage": {
      return {
        ...state,
        user: { ...state.user, error: action.message },
      };
    }
    case "user/hello": {
      return {
        ...state,
        user: { ...state.user, hello: true },
      };
    }
    case "user/logged": {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
          loggedIn: action.user.sessionId ? true : false,
        },
      };
    }
    case "user/setSessionId": {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
          sessionId: action.sessionId,
          sessionSaved: action.save,
        },
      };
    }
    default:
      return state;
  }
}
