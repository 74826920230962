import React, { Component } from "react";
import { Button } from "reactstrap";

class PexesoGameCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image,
      blankImage: this.props.blankImage,
      wrongFade: false,
      successFade: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === true && this.props.visible === false) {
      if (this.props.solver) {
        this.setState({ successFade: true });
        setTimeout(() => {
          this.setState({ successFade: false });
        }, 1000);
      } else {
        this.setState({ wrongFade: true });
        setTimeout(() => {
          this.setState({ wrongFade: false });
        }, 250);
      }
    }
  }

  render() {
    const visible = this.props.visible;
    const solver = this.props.solver;
    const solved = solver ? true : false;
    const turn = this.props.turn;
    const visibleColor = this.props.visibleColor;
    const solvedByUser = this.props.users.find((f) => f.userName === solver);
    const solvedColor = solvedByUser ? solvedByUser.color : "red";
    return (
      <Button
        style={
          this.state.wrongFade || this.state.successFade
            ? {}
            : visible
            ? {
                backgroundColor: visibleColor,
                borderColor: visibleColor,
              }
            : solved
            ? {
                backgroundColor: solvedColor,
                borderColor: solvedColor,
              }
            : {}
        }
        className={
          visible || this.state.wrongFade || this.state.successFade
            ? "visible-btn"
            : solved
            ? "solved-btn"
            : "img-btn"
        }
        color={
          this.state.wrongFade
            ? "danger"
            : this.state.successFade
            ? "success"
            : visible
            ? "danger"
            : solved
            ? "success"
            : "default"
        }
        disabled={!turn || solved || visible}
        onClick={() => this.props.onClick()}
      >
        <div className="img-wrap">
          <img
            src={visible || solved ? this.state.image : this.state.blankImage}
          />
        </div>
      </Button>
    );
  }
}

export default PexesoGameCard;
