import { initialState } from "./store";

export default function wordReducer(state = initialState, action) {
  switch (action.type) {
    case "reset": {
      return {
        word: { ...initialState.word },
      };
    }
    case "errorMessage": {
      return {
        word: { ...state.word, error: action.message },
      };
    }
    case "word/lobby": {
      return {
        word: { ...state.word, lobby: action.lobby },
      };
    }
    case "word/createOrJoinGame": {
      return {
        word: {
          ...state.word,
          game: { ...action.game },
          lobby: undefined,
          creation: action.create ? true : false,
          joined: action.create ? false : true,
        },
      };
    }
    case "word/updateGame": {
      return {
        word: {
          ...state.word,
          lobby: undefined,
          game: {
            ...state.word.game,
            ...action.game,
            users: action.game.users,
          },
          cards: action.game.cards,
        },
      };
    }
    case "word/goBackToLobby": {
      return {
        word: {
          ...state.word,
          game: { ...action.game },
          lobby: undefined,
          creation: false,
          joined: false,
        },
      };
    }
    case "word/goToLobby": {
      return {
        word: { ...initialState.word },
      };
    }
    case "word/updateOwnWords": {
      return {
        word: {
          ...state.word,
          error: "",
          ownWords: action.words,
        },
      };
    }
    case "word/updateStyles": {
      return {
        word: {
          ...state.word,
          styles: action.styles,
        },
      };
    }
    default:
      return state;
  }
}
