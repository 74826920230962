import { withCallbacks } from "redux-signalr";

export default withCallbacks()
  .add("ReceiveHello", () => async (dispatch) => {
    dispatch({ type: "user/hello" });
  })
  .add("ReceiveLoggedIn", (user) => async (dispatch) => {
    dispatch({ type: "user/logged", user });
  })
  .add("ReceivePexesoLobby", (lobby) => async (dispatch) => {
    dispatch({ type: "pexeso/lobby", lobby });
  })
  .add("CreateOrJoinPexesoGame", (game, create) => async (dispatch) => {
    dispatch({ type: "pexeso/createOrJoinGame", game, create });
  })
  .add("ReceiveUpdatePexesoGame", (game) => async (dispatch) => {
    dispatch({ type: "pexeso/updateGame", game });
  })
  .add("ReceiveErrorMessage", (message) => async (dispatch) => {
    dispatch({ type: "errorMessage", message });
  })
  .add("ReceiveBackToPexesoLobby", () => async (dispatch) => {
    dispatch({ type: "pexeso/goBackToLobby" });
  })
  .add("ReceiveStyles", (styles) => async (dispatch) => {
    dispatch({ type: "pexeso/updateStyles", styles });
  })
  .add("ReceiveWordLobby", (lobby) => async (dispatch) => {
    dispatch({ type: "word/lobby", lobby });
  })
  .add("CreateOrJoinWordGame", (game, create) => async (dispatch) => {
    dispatch({ type: "word/createOrJoinGame", game, create });
  })
  .add("ReceiveUpdateWordGame", (game) => async (dispatch) => {
    dispatch({ type: "word/updateGame", game });
  })
  .add("ReceiveOwnUpdateWordGame", (words) => async (dispatch) => {
    dispatch({ type: "word/updateOwnWords", words });
  })
  .add("ReceiveBackToWordLobby", () => async (dispatch) => {
    dispatch({ type: "word/goBackToLobby" });
  });
