import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { Table } from "reactstrap";
import { connect } from "react-redux";

class PexesoScore extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const userInTurn = this.props.pexesoInfo.game.users.find(
      (f) => f.turn === true
    ).userName;
    return (
      <div>
        <Table responsive>
          <tbody>
            <tr>
              {this.props.pexesoInfo.game.users.map((item) => (
                <td key={"td-" + item.userName}>
                  <div
                    style={
                      item.userName === userInTurn
                        ? {
                            padding: "3px",
                            width: "fit-content",
                            border: "3px solid " + item.color,
                            borderRadius: "7px",
                            boxShadow: "0 0 10px " + item.color,
                          }
                        : {
                            padding: "3px",
                            width: "fit-content",
                            border: "3px solid " + item.color,
                          }
                    }
                  >
                    {item.userName} - {item.score}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoScore);
