import React, { Component } from "react";
import { Table } from "reactstrap";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { connect } from "react-redux";

class PreviewComponent extends Component {
  render() {
    return (
      <Table responsive>
        <tbody>
          <tr>
            <td key={this.props.pexesoInfo.game.blankCard}>
              <div className="img-wrap">
                <img src={this.props.pexesoInfo.game.blankCard} />
              </div>
            </td>

            {this.props.pexesoInfo.game.cardsPreview.map((item) => (
              <td key={"preview" + item}>
                <div className="img-wrap">
                  <img src={item} />
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PreviewComponent);
