import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
} from "reactstrap";
import { connect } from "react-redux";

class WordResultModal extends Component {
  constructor(props) {
    super(props);
    this.goToLobby = this.goToLobby.bind(this);
  }

  goToLobby() {
    this.props.goToLobby();
  }

  itemChunks(inputArray) {
    return inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 5);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  render() {
    const sortedPlayers = this.props.wordInfo.game.users.sort(function (a, b) {
      return b.score - a.score;
    });
    var moreWinners = sortedPlayers.filter(
      (f) => f.score === sortedPlayers[0].score
    );
    return (
      <Modal isOpen={this.props.wordInfo.game.resolved}>
        <ModalHeader>A vítězem se stává....</ModalHeader>
        <ModalBody>
          {moreWinners && moreWinners.length > 1 ? (
            <>
              Ojoj vypadá to na remízu mezi hráči:
              <br />
              <b>{moreWinners.map((s) => s.userName).join(", ")}</b>
            </>
          ) : (
            <>
              Hráč jménem: <b>{sortedPlayers[0].userName}</b>
              <h5>Gratulujeme</h5>
            </>
          )}
          <br />
          <Table>
            <tbody>
              <tr>
                <th>Jméno</th>
                <th>Skore</th>
              </tr>
              {sortedPlayers.map((item) => (
                <tr style={{ color: item.color ?? "red" }} key={item.userName}>
                  <td>{item.userName}</td>
                  <td>{item.score}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table>
            <tbody>
              {this.itemChunks(this.props.wordInfo.game.resultWordEntries).map(
                (words, index) => (
                  <tr key={index}>
                    {words.map((word) => (
                      <td style={{ minWidth: "60px" }} key={word.word}>
                        {word.userColors.map((userColor, index_color) => (
                          <span
                            style={{
                              width: "10px",
                              height: "20px",
                              color: userColor,
                            }}
                            key={index + "" + index_color}
                          >
                            ||
                          </span>
                        ))}
                        &nbsp;
                        {word.word}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.goToLobby()}>
            Zpět do Lobby
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  mapWordStateToProps,
  mapWordDispatchToProps
)(WordResultModal);
