import { NodeGroup } from "react-move";
import { easeElastic } from "d3-ease";
import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import { connect } from "react-redux";

class WordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitForTimeOut: false,
      input: "",
      used: [],
      x: 0,
      y: 0,
    };
    this.handlekeydown = this.handlekeydown.bind(this);
    this.addEntry = this.addEntry.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handlekeydown);

    const letterMap = Array.from(this.props.wordInfo.game.letters).map(
      (char, indexNumber) => {
        return {
          index: indexNumber,
          letter: char,
          used: false,
          displayIndex: 0,
        };
      }
    );

    this.setState({ ...this.state, used: letterMap });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handlekeydown);
  }

  stringCount(input, key) {
    const re = new RegExp(key, "g");
    return input.match(re) ? input.match(re).length : 0;
  }

  handlekeydown(event) {
    const key = event.key;

    let inputString = this.state.input;
    let usedLetters = this.state.used;

    if (this.props.wordInfo.game.letters.includes(key)) {
      const lettersCount = this.stringCount(
        this.props.wordInfo.game.letters,
        key
      );

      const usedLettersCount = this.stringCount(this.state.input, key);
      if (usedLettersCount < lettersCount) {
        const usedItem = usedLetters.filter(
          (f) => f.letter === key && f.used === false
        );

        const usedIndex = usedItem[0].index;

        usedLetters[usedIndex] = {
          ...usedLetters[usedIndex],
          used: true,
          displayIndex: inputString.length,
        };

        inputString = inputString + key;
      }
    } else if (event.keyCode === 8 && inputString.length > 0) {
      const lastChar = inputString.substring(inputString.length - 1);

      const lastUsedIndex = usedLetters.filter(
        (f) => f.letter === lastChar && f.used === true
      );

      const usedIndex = lastUsedIndex[lastUsedIndex.length - 1].index;
      usedLetters[usedIndex] = {
        ...usedLetters[usedIndex],
        used: false,
      };

      inputString = inputString.substring(0, inputString.length - 1);
    } else if (event.keyCode === 13 && inputString.length > 0) {
      this.addEntry(inputString);

      inputString = "";
      usedLetters.forEach((element) => (element.used = false));
    }

    this.setState({
      ...this.state,
      input: inputString,
      used: usedLetters,
    });
  }

  addEntry(entry) {
    this.props.addWordEntry(this.props.wordInfo.game.id, entry);
  }

  render() {
    return (
      <div
        id="input-box-parent"
        style={{
          height: "200px",
          padding: "20px",
          borderRadius: "25px",
          border: "2px solid #73AD21",
        }}
        className="fill-area-content flexbox-item-grow"
      >
        <NodeGroup
          data={this.state.used.map((letter, index) => {
            const rowSize = 40;
            var parent =
              document.getElementById("input-box-parent").clientWidth;
            var mid = parent / 2;
            var used = this.state.used.filter((f) => f.used === true).length;
            var singleWidth = parent / this.state.used.length;
            if (letter.used) {
              return {
                key: `key-${index}`,
                value: letter.letter,
                x:
                  mid -
                  (used * singleWidth) / 2 +
                  letter.displayIndex * singleWidth,
                y: rowSize * 2,
              };
            } else {
              return {
                key: `key-${index}`,
                value: letter.letter,
                x: index * singleWidth,
                y: rowSize * 3,
              };
            }
          })}
          keyAccessor={(d) => d.key}
          start={(data) => {
            return { x: data.x, y: data.y };
          }}
          update={(data) => {
            return {
              x: [data.x],
              y: [data.y],
              timing: {
                delay: 120,
                duration: 500,
                ease: easeElastic,
              },
            };
          }}
        >
          {(nodes) => (
            <div>
              {nodes.map(({ key, data, state: { x, y } }) => (
                <span
                  key={key}
                  style={{
                    position: "absolute",
                    WebkitTransform: `translate3d(${x}px, ${y}px, 0)`,
                    transform: `translate3d(${x}px, ${y}px, 0)`,
                  }}
                >
                  {data.value}
                </span>
              ))}
            </div>
          )}
        </NodeGroup>
        <div
          style={{
            visibility: this.props.wordInfo.error ? "visible" : "hidden",
          }}
        >
          {this.props.wordInfo.error}
        </div>
      </div>
    );
  }
}

export default connect(mapWordStateToProps, mapWordDispatchToProps)(WordInput);
