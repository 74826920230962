import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import UserList from "./UserList";

class WordCreation extends Component {
  constructor(props) {
    super(props);
    this.startGame = this.startGame.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.leaveGame = this.leaveGame.bind(this);

    this.state = {
      dropdownOpen: false,
      setStyle: false,
    };
  }

  componentDidMount() {
    //this.props.getPexesoCardStyles();
  }

  startGame() {
    this.props.startWordGame(this.props.wordInfo.game.id);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleChangeDropdown(style) {
    this.setState({ setStyle: true });
    this.props.configureWordGame(this.props.wordInfo.game.id, style);
  }

  leaveGame() {
    this.props.leaveWordGame(this.props.wordInfo.game.id);
  }

  render() {
    return (
      <div>
        <h4>
          Nová hra založena <b>Id: {this.props.wordInfo.game.id}</b>
        </h4>
        <nav className="navbar navbar-expand-lg">
          {/* <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>Vybrat kartičky</DropdownToggle>
             <DropdownMenu>
              {this.props.wordInfo.styles.map((item) => (
                <DropdownItem
                  onClick={() => this.handleChangeDropdown(item.id)}
                  key={item.id}
                >
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
              </Dropdown> */}
          <Button onClick={() => this.leaveGame()}>Odejít</Button>
        </nav>

        <UserList />
        <Button
          // disabled={!this.state.setStyle}
          onClick={() => this.startGame()}
        >
          Spustit hru
        </Button>
      </div>
    );
  }
}

export default connect(
  mapWordStateToProps,
  mapWordDispatchToProps
)(WordCreation);
