import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { Table, Button } from "reactstrap";
import { connect } from "react-redux";

class PexesoLobby extends Component {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.backToGameSelection = this.backToGameSelection.bind(this);
  }

  componentDidMount() {
    this.props.getPexesoLobby();
  }

  joinGame(gameName) {
    this.props.joinPexesoGame(gameName);
  }

  chunk(arr, chunkSize) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
    return R;
  }

  chunkSize(itemCount) {
    switch (itemCount) {
      case 64: {
        return 8;
      }
      case 32: {
        return 6;
      }
      case 16: {
        return 4;
      }
      case 8: {
        return 4;
      }
      default: {
        return 4;
      }
    }
  }

  onCreate() {
    this.props.createPexesoGame();
  }

  backToGameSelection() {
    this.props.backToGameSelection();
  }

  render() {
    return (
      <div>
        <h4>{this.props.pexesoInfo.error}</h4>
        <nav className="navbar navbar-expand-lg">
          <Button onClick={this.onCreate}>Vytvořit</Button>
          <Button onClick={this.backToGameSelection}>Odejít</Button>
        </nav>
        {this.props.pexesoInfo.lobby &&
        this.props.pexesoInfo.lobby.length > 0 ? (
          <Table responsive>
            <tbody>
              {this.props.pexesoInfo.lobby.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    <Button onClick={() => this.joinGame(item.id)}>
                      Připojit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Zadne hry</p>
        )}
      </div>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoLobby);
