import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { mapUserDispatchToProps } from "../store/actions";
import { mapUserStateToProps } from "../store/store";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

class LoginComponent extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      userName: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSubmit() {
    this.props.setUserName(this.state.userName);
  }

  componentDidMount() {
    const { cookies } = this.props;
    const sessionId = cookies.get("sessionId");
    if (
      sessionId &&
      !this.props.userInfo.loggedIn &&
      !this.props.userInfo.sessionId
    ) {
      this.props.setSessionToStore(sessionId, false);
    }
  }

  componentDidUpdate() {
    if (this.props.userInfo.loggedIn && !this.props.userInfo.sessionSaved) {
      const { cookies } = this.props;
      cookies.set("sessionId", this.props.userInfo.sessionId);
      this.props.setSessionToStore(this.props.userInfo.sessionId, true);
    } else {
      if (this.props.userInfo.sessionId) {
        this.props.logginWithSession(this.props.userInfo.sessionId);
      }
    }
  }

  render() {
    if (this.props.userInfo.sessionId) {
      return (
        <div>
          <h2>Ahoj, světe</h2>
          <h4>Věřím že to jde kvalitně a bez reklam!!</h4>
          <div>Pripojen</div>
        </div>
      );
    } else {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <h2>Nejsi přihlásen</h2>
          <Row>
            <Col>
              <FormGroup>
                <Label for="userName">Uživatelské jméno</Label>
                <Input
                  onChange={this.handleInputChange}
                  value={this.state.userName}
                  type="text"
                  name="userName"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-right">
            <Button onClick={this.onSubmit}>Přihlásit</Button>
          </div>
        </Container>
      );
    }
  }
}

export default connect(
  mapUserStateToProps,
  mapUserDispatchToProps
)(withCookies(LoginComponent));
