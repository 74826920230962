import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import Home from "./components/Home";
import Pexeso from "./components/Pexeso";
import Word from "./components/Word";
import "./custom.css";

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Route exact path="/" component={Home} />
        <Route exact path="/pexeso" component={Pexeso} />
        <Route exact path="/word" component={Word} />
      </Layout>
    );
  }
}
