import "bootstrap/dist/css/bootstrap.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { IntlProvider } from "react-intl";
import { LastLocationProvider } from "react-router-last-location";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import connection from "./store/connection";
import { CookiesProvider } from "react-cookie";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const store = configureStore();

connection.onreconnected(() => {
  store.dispatch({ type: "reset" });
});

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <CookiesProvider>
      <LastLocationProvider>
        <IntlProvider locale={navigator.language}>
          <Provider store={store}>
            <App />
          </Provider>
        </IntlProvider>
      </LastLocationProvider>
    </CookiesProvider>
  </BrowserRouter>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
