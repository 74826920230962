import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import PexesoScore from "./Score";
import ResultModal from "./ResultModal";
import GameCard from "./GameCard";

class PexesoGame extends Component {
  constructor(props) {
    super(props);
    this.flipCard = this.flipCard.bind(this);
    this.state = {
      waitForTimeOut: false,
      chunkSize: this.chunkSize(this.props.pexesoInfo.cards.length),
    };
  }

  chunk(arr, chunkSize) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));
    return R;
  }

  chunkSize(itemCount) {
    if (itemCount > 8) {
      if (itemCount > 24) {
        if (itemCount > 32) {
          if (itemCount >= 64) {
            if (itemCount > 128) {
              return 14;
            } else {
              return 13;
            }
          } else {
            return 12;
          }
        } else {
          return 10;
        }
      } else {
        return 12;
      }
    }
    return 8;
  }

  flipCard(cardId) {
    this.props.flipPexesoCard(this.props.pexesoInfo.game.id, cardId);
  }

  render() {
    const visibleColor = this.props.pexesoInfo.game.users.find(
      (f) => f.turn === true
    ).color;
    const playerTurn = this.props.pexesoInfo.game.users.find(
      (f) => f.userName === this.props.userInfo.userName
    ).turn;
    return (
      <div className="flexbox-parent">
        <Container fluid className="flexbox-item header">
          <h4>
            Pexeso hra <b>Id:{this.props.pexesoInfo.game.id}</b>
          </h4>
          <PexesoScore />
        </Container>
        <Container
          fluid
          className="flexbox-item fill-area content flexbox-item-grow"
        >
          <div className="fill-area-content flexbox-item-grow">
            <table>
              <tbody>
                {this.chunk(
                  this.props.pexesoInfo.cards,
                  this.state.chunkSize
                ).map((row) => (
                  <tr
                    key={"tr-" + row[0].id}
                    lg={this.state.chunkSize}
                    md={this.state.chunkSize}
                    sm={this.state.chunkSize}
                    xl={this.state.chunkSize}
                    xs={this.state.chunkSize}
                    xxl={this.state.chunkSize}
                  >
                    {row.map((item) => (
                      <td key={"td-" + item.id}>
                        <GameCard
                          visible={item.visible}
                          solver={item.solver}
                          turn={playerTurn}
                          image={item.value}
                          visibleColor={visibleColor}
                          blankImage={this.props.pexesoInfo.game.blankCard}
                          users={this.props.pexesoInfo.game.users}
                          onClick={() => this.flipCard(item.id)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ResultModal />
        </Container>
      </div>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoGame);
