import React, { Component } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";

export class Logo extends Component {
  render() {
    return (
      <NavLink
        tag={Link}
        to="/"
      >
        <img
          alt="BlackZone"
          src="/static/logo.png"
          style={{
            height: "7.1rem",
          }}
        />
      </NavLink>
    );
  }
}
