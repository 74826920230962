import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import GameInput from "./Input";
import OwnList from "./OwnList";
import ResultModal from "./ResultModal";
class WordGame extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="backRows" style={{ padding: "15px" }}>
        <div className="flexbox-parent">
          <Container fluid className="flexbox-item header">
            <h4>
              Slovicka hra <b>Id:{this.props.wordInfo.game.id}</b>
            </h4>
          </Container>

          <Container
            fluid
            className="flexbox-item fill-area content flexbox-item-grow"
          >
            <OwnList />
          </Container>
          <Container
            fluid
            className="flexbox-item fill-area content flexbox-item-grow"
          >
            <GameInput />
          </Container>
        </div>
        <ResultModal />
      </Container>
    );
  }
}
export default connect(mapWordStateToProps, mapWordDispatchToProps)(WordGame);
