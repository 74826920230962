import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { Canavas } from "./Canavas";
import "../custom.css";

export class Layout extends PureComponent {
  render() {
    return (
      <>
        <Canavas />
        <Container>
          <Row className="backRows flexbox-item header">
            <Col>
              <NavMenu />
            </Col>
          </Row>
        </Container>
        <div className="flexbox-parent">
          <Row
            style={{ marginLeft: "10px", marginRight: "10px" }}
            className="backRows content flexbox-item fill-area content flexbox-item-grow"
          >
            <Col className="fill-area-content flexbox-item-grow flexbox-relative">
              {this.props.children}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
