import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import { connect } from "react-redux";
import PreviewComponent from "./Preview";
import UserList from "./UserList";
import { Button } from "reactstrap";

class PexesoWait extends Component {
  constructor(props) {
    super(props);
    this.leaveGame = this.leaveGame.bind(this);
  }

  leaveGame() {
    this.props.leavePexesoGame(this.props.pexesoInfo.game.id);
  }

  render() {
    return (
      <div>
        <h4>
          Připojen ke hře <b>Id:{this.props.pexesoInfo.game.id}</b>
        </h4>
        <nav className="navbar navbar-expand-lg">
          <Button onClick={() => this.leaveGame()}>Odejít</Button>
        </nav>
        <br />
        <PreviewComponent />
        <hr />
        <UserList />
      </div>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoWait);
