import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import { Table, Button } from "reactstrap";
import { connect } from "react-redux";

class WordLobby extends Component {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.backToGameSelection = this.backToGameSelection.bind(this);
  }

  componentDidMount() {
    this.props.getWordLobby();
  }

  joinGame(gameName) {
    this.props.joinWordGame(gameName);
  }

  onCreate() {
    this.props.createWordGame();
  }

  backToGameSelection() {
    this.props.backToGameSelection();
  }

  render() {
    return (
      <div>
        <h4>{this.props.wordInfo.error}</h4>
        <nav className="navbar navbar-expand-lg">
          <Button onClick={this.onCreate}>Vytvořit</Button>
          <Button onClick={this.backToGameSelection}>Odejít</Button>
        </nav>
        {this.props.wordInfo.lobby && this.props.wordInfo.lobby.length > 0 ? (
          <Table responsive>
            <tbody>
              {this.props.wordInfo.lobby.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    <Button onClick={() => this.joinGame(item.id)}>
                      Připojit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Zadne hry</p>
        )}
      </div>
    );
  }
}

export default connect(mapWordStateToProps, mapWordDispatchToProps)(WordLobby);
