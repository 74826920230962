import React, { Component } from "react";
import { connect } from "react-redux";
import { mapUserDispatchToProps } from "../store/actions";
import { mapUserStateToProps } from "../store/store";
import { NavLink, Container } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Login from "./Login";

class HomeComponent extends Component {
  render() {
    if (!this.props.userInfo.hello) {
      return (
        <div>
          <h2>Ahoj, světe</h2>
          <h4>Věřím že to jde kvalitně a bez reklam!!</h4>
          <div>Připojování</div>
        </div>
      );
    } else if (
      this.props.userInfo.loggedIn === false ||
      this.props.userInfo.sessionSaved !== true
    ) {
      return <Login />;
    } else {
      if (this.props.userInfo.game) {
        if (this.props.userInfo.game == 1) {
          return <Redirect to="/pexeso" />;
        }
        if (this.props.userInfo.game == 2) {
          return <Redirect to="/word" />;
        }
      } else {
        return (
          <Container className="backRows" style={{ padding: "15px" }}>
            <h2>Ahoj, světe</h2>
            <h4>Věřím že to jde kvalitně a bez reklam!!</h4>
            <NavLink tag={Link} to="/pexeso">
              Pexeso
            </NavLink>
            <NavLink tag={Link} to="/word">
              Word
            </NavLink>
          </Container>
        );
      }
    }
  }
}

export default connect(
  mapUserStateToProps,
  mapUserDispatchToProps
)(HomeComponent);
