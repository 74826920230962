import React, { PureComponent } from 'react'
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { Logo } from './Logo'

export class NavMenu extends PureComponent {

  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true,
    }
  }

  toggleNavbar() {
     this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-main navbar-expand-md border-bottom mb-3"
          dark
        >
          <Container>
            <Logo />
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse
              className="d-md-inline-flex flex-md-row-reverse"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} to="/">
                    Domů
                  </NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}
