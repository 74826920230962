import { initialState } from "./store";

export default function pexesoReducer(state = initialState, action) {
  switch (action.type) {
    case "reset": {
      return {
        pexeso: { ...initialState.pexeso },
      };
    }
    case "errorMessage": {
      return {
        pexeso: { ...state.pexeso, error: action.message },
      };
    }
    case "pexeso/lobby": {
      return {
        pexeso: { ...state.pexeso, lobby: action.lobby },
      };
    }
    case "pexeso/createOrJoinGame": {
      return {
        pexeso: {
          ...state.pexeso,
          game: { ...action.game },
          lobby: undefined,
          creation: action.create ? true : false,
          joined: action.create ? false : true,
        },
      };
    }
    case "pexeso/updateGame": {
      return {
        pexeso: {
          ...state.pexeso,
          lobby: undefined,
          game: {
            ...state.pexeso.game,
            ...action.game,
            users: action.game.users,
          },
          cards: action.game.cards,
        },
      };
    }
    case "pexeso/goBackToLobby": {
      return {
        pexeso: {
          ...state.pexeso,
          game: { ...action.game },
          lobby: undefined,
          creation: false,
          joined: false,
        },
      };
    }
    case "pexeso/goToLobby": {
      return {
        pexeso: { ...initialState.pexeso },
      };
    }
    case "pexeso/updateStyles": {
      return {
        pexeso: {
          ...state.pexeso,
          styles: action.styles,
        },
      };
    }
    default:
      return state;
  }
}
