import React, { Component } from "react";
import { connect } from "react-redux";
import { mapPexesoDispatchToProps } from "../store/actions";
import { mapPexesoStateToProps } from "../store/store";
import { Redirect } from "react-router-dom";
import PexesoGame from "./Pexeso/Game";
import PexesoLobby from "./Pexeso/Lobby";
import PexesoCreation from "./Pexeso/Creation";
import PexesoWait from "./Pexeso/Wait";
import { Container } from "reactstrap";

class PexesoComponent extends Component {
  render() {
    if (!this.props.userInfo.loggedIn) {
      return <Redirect to="/" />;
    } else if (
      this.props.pexesoInfo.game.running &&
      this.props.pexesoInfo.cards
    ) {
      return <PexesoGame />;
    } else if (this.props.pexesoInfo.joined) {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <PexesoWait />
        </Container>
      );
    } else if (this.props.pexesoInfo.creation) {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <PexesoCreation />
        </Container>
      );
    } else {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <PexesoLobby />
        </Container>
      );
    }
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoComponent);
