import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
} from "reactstrap";
import { connect } from "react-redux";

class PexesoResultModal extends Component {
  constructor(props) {
    super(props);
    this.goToLobby = this.goToLobby.bind(this);
  }

  goToLobby() {
    this.props.goToLobby();
  }

  render() {
    const sortedPlayers = this.props.pexesoInfo.game.users.sort(function (
      a,
      b
    ) {
      return b.score - a.score;
    });
    var moreWinners = sortedPlayers.filter(
      (f) => f.score === sortedPlayers[0].score
    );
    return (
      <Modal
        isOpen={
          this.props.pexesoInfo.cards.filter((f) => f.solved).length ===
          this.props.pexesoInfo.cards.length
            ? true
            : false
        }
      >
        <ModalHeader>A vítězem se stává....</ModalHeader>
        <ModalBody>
          {moreWinners && moreWinners.length > 1 ? (
            <>
              Ojoj vypadá to na remízu mezi hráči:
              <br />
              <b>{moreWinners.map((s) => s.userName).join(", ")}</b>
            </>
          ) : (
            <>
              Hráč jménem: <b>{sortedPlayers[0].userName}</b>
              <h5>Gratulujeme</h5>
            </>
          )}
          <br />
          <Table>
            <tbody>
              <tr>
                <th>Jméno</th>
                <th>Skore</th>
              </tr>
              {sortedPlayers.map((item) => (
                <tr style={{ color: item.color ?? "red" }} key={item.userName}>
                  <td>{item.userName}</td>
                  <td>{item.score}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.goToLobby()}>
            Zpět do Lobby
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoResultModal);
