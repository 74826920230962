import { createStore, applyMiddleware } from "redux";
import { rootReducer, signal } from "./reducer";

export const initialState = {
  user: {
    error: "",
    hello: false,
    userName: "",
    loggedIn: false,
    game: 0,
    sessionSaved: false,
    turn: false,
    color: "",
  },
  pexeso: {
    error: "",
    creation: false,
    joined: false,
    lobby: undefined,
    styles: [],
    game: {
      blankCard: "",
      cardsPreview: [],
      inLobby: false,
      id: "",
      name: "",
      users: [],
      maxUsers: 0,
      running: false,
    },
    cards: [],
  },
  word: {
    error: "",
    creation: false,
    joined: false,
    lobby: undefined,
    ownWords: [],
    game: {
      inLobby: false,
      running: false,
      id: "",
      name: "",
      maxUsers: 0,
      users: [],
      wordEntries: [],
      time: "",
      dictionary: -1,
      letters: "",
      lettersCount: 0,
      language: "",
      resolved: false,
      resultWordEntries: [],
    },
  },
};

export default function configureStore() {
  return createStore(rootReducer, applyMiddleware(signal));
}

export function mapUserStateToProps(state) {
  const { user } = state.user;
  return { userInfo: user };
}

export function mapPexesoStateToProps(state) {
  const { user } = state.user;
  const { pexeso } = state.pexeso;
  return { pexesoInfo: pexeso, userInfo: user };
}

export function mapWordStateToProps(state) {
  const { user } = state.user;
  const { word } = state.word;
  return { wordInfo: word, userInfo: user };
}
