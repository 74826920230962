import React, { Component } from "react";
import { mapPexesoDispatchToProps } from "../../store/actions";
import { mapPexesoStateToProps } from "../../store/store";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import PreviewComponent from "./Preview";
import UserList from "./UserList";

class PexesoCreation extends Component {
  constructor(props) {
    super(props);
    this.startGame = this.startGame.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.leaveGame = this.leaveGame.bind(this);

    this.state = {
      dropdownOpen: false,
      setStyle: false,
    };
  }

  componentDidMount() {
    this.props.getPexesoCardStyles();
  }

  startGame() {
    this.props.startPexesoGame(this.props.pexesoInfo.game.id);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleChangeDropdown(style) {
    this.setState({ setStyle: true });
    this.props.configurePexesoGame(this.props.pexesoInfo.game.id, style);
  }

  leaveGame() {
    this.props.leavePexesoGame(this.props.pexesoInfo.game.id);
  }

  render() {
    return (
      <div>
        <h4>
          Nová hra založena <b> Id:{this.props.pexesoInfo.game.id}</b>
        </h4>
        <nav className="navbar navbar-expand-lg">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>Vybrat kartičky</DropdownToggle>
            <DropdownMenu>
              {this.props.pexesoInfo.styles.map((item) => (
                <DropdownItem
                  onClick={() => this.handleChangeDropdown(item.id)}
                  key={item.id}
                >
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Button onClick={() => this.leaveGame()}>Odejít</Button>
        </nav>
        <PreviewComponent />
        <UserList />
        <Button
          disabled={!this.state.setStyle}
          onClick={() => this.startGame()}
        >
          Spustit hru
        </Button>
      </div>
    );
  }
}

export default connect(
  mapPexesoStateToProps,
  mapPexesoDispatchToProps
)(PexesoCreation);
