import React, { Component } from "react";
import { connect } from "react-redux";
import { mapWordDispatchToProps } from "../store/actions";
import { mapWordStateToProps } from "../store/store";
import { Redirect } from "react-router-dom";
import WordGame from "./Word/Game";
import WordLobby from "./Word/Lobby";
import WordCreation from "./Word/Creation";
import WordWait from "./Word/Wait";
import { Container } from "reactstrap";

class WordComponent extends Component {
  render() {
    if (!this.props.userInfo.loggedIn) {
      return <Redirect to="/" />;
    } else if (this.props.wordInfo.game.running) {
      return <WordGame />;
    } else if (this.props.wordInfo.joined) {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <WordWait />
        </Container>
      );
    } else if (this.props.wordInfo.creation) {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <WordCreation />
        </Container>
      );
    } else {
      return (
        <Container className="backRows" style={{ padding: "15px" }}>
          <WordLobby />
        </Container>
      );
    }
  }
}

export default connect(
  mapWordStateToProps,
  mapWordDispatchToProps
)(WordComponent);
