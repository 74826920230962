const setUserName = (userName) => (_dispatch, _getState, invoke) => {
  invoke("setUserName", userName);
};

export const logginWithSession = (user) => (_dispatch, _getState, invoke) => {
  invoke("logginWithSession", user);
};

const getPexesoLobby = () => (_dispatch, _getState, invoke) => {
  invoke("getPexesoLobby");
};

const createPexesoGame = () => (_dispatch, _getState, invoke) => {
  invoke("createPexesoGame");
};

const joinPexesoGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("joinPexesoGame", gameName);
};

const startPexesoGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("startPexesoGame", gameName);
};

const getPexesoCardStyles = () => (_dispatch, _getState, invoke) => {
  invoke("getPexesoStyles");
};

const configurePexesoGame =
  (gameName, style) => (_dispatch, _getState, invoke) => {
    invoke("configurePexesoGame", gameName, style);
  };

const flipPexesoCard = (gameName, cardId) => (_dispatch, _getState, invoke) => {
  invoke("flipPexesoCard", gameName, cardId);
};

const leavePexesoGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("leavePexesoGame", gameName);
};

const changeUserColorPexeso =
  (gameName, color) => (_dispatch, _getState, invoke) => {
    invoke("changeUserColorPexeso", gameName, color);
  };

const getWordLobby = () => (_dispatch, _getState, invoke) => {
  invoke("getWordLobby");
};

const createWordGame = () => (_dispatch, _getState, invoke) => {
  invoke("createWordGame");
};

const joinWordGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("joinWordGame", gameName);
};

const startWordGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("startWordGame", gameName);
};

const configureWordGame =
  (gameName, style) => (_dispatch, _getState, invoke) => {
    invoke("configureWordGame", gameName, style);
  };

const addWordEntry = (gameName, entry) => (_dispatch, _getState, invoke) => {
  invoke("addWordEntry", gameName, entry);
};

const leaveWordGame = (gameName) => (_dispatch, _getState, invoke) => {
  invoke("leaveWordGame", gameName);
};

const changeUserColorWord =
  (gameName, color) => (_dispatch, _getState, invoke) => {
    invoke("changeUserColorWord", gameName, color);
  };

const backToGameSelection = () => (_dispatch, _getState, invoke) => {
  invoke("backToGameSelection");
};

export const mapUserDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setSessionToStore: (sessionId, save) =>
      dispatch({ type: "user/setSessionId", sessionId, save }),
    logginWithSession: (sessionId) => dispatch(logginWithSession(sessionId)),
    setUserName: (userName) => dispatch(setUserName(userName)),
  };
};

export const mapPexesoDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    configurePexesoGame: (gameName, style) =>
      dispatch(configurePexesoGame(gameName, style)),
    getPexesoCardStyles: () => dispatch(getPexesoCardStyles()),
    getPexesoLobby: () => dispatch(getPexesoLobby()),
    createPexesoGame: () => dispatch(createPexesoGame()),
    joinPexesoGame: (gameName) => dispatch(joinPexesoGame(gameName)),
    startPexesoGame: (gameName) => dispatch(startPexesoGame(gameName)),
    flipPexesoCard: (gameName, cardId) =>
      dispatch(flipPexesoCard(gameName, cardId)),
    goToLobby: () => dispatch({ type: "pexeso/goToLobby" }),
    leavePexesoGame: (gameName) => dispatch(leavePexesoGame(gameName)),
    changeUserColorPexeso: (gameName, color) =>
      dispatch(changeUserColorPexeso(gameName, color)),
    backToGameSelection: () => dispatch(backToGameSelection()),
  };
};

export const mapWordDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    configureWordGame: (gameName, style) =>
      dispatch(configureWordGame(gameName, style)),
    getWordLobby: () => dispatch(getWordLobby()),
    createWordGame: () => dispatch(createWordGame()),
    joinWordGame: (gameName) => dispatch(joinWordGame(gameName)),
    startWordGame: (gameName) => dispatch(startWordGame(gameName)),
    goToLobby: () => dispatch({ type: "word/goToLobby" }),
    leaveWordGame: (gameName) => dispatch(leaveWordGame(gameName)),
    changeUserColorWord: (gameName, color) =>
      dispatch(changeUserColorWord(gameName, color)),
    addWordEntry: (gameName, entry) => dispatch(addWordEntry(gameName, entry)),
    backToGameSelection: () => dispatch(backToGameSelection()),
  };
};
