import { HubConnectionBuilder, LogLevel, HttpTransportType} from '@microsoft/signalr'

const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(process.env.REACT_APP_WS_HOST+'/hubs/games', {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Debug)
  .build();

export default connection;