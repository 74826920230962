import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import { connect } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

class WordOwnList extends Component {
  constructor(props) {
    super(props);
  }

  remainingTime() {
    const utcTimestamp = new Date().getTime() / 1000;
    return this.props.wordInfo.game.time - utcTimestamp;
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  itemChunks(inputArray) {
    return inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 5);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "inline-block",
            margin: "20px",
          }}
        >
          <CountdownCircleTimer
            isPlaying
            duration={this.remainingTime()}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[7, 5, 2, 0]}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
        <div
          style={{
            display: "inline-block",
            margin: "20px",
            padding: "20px",
            borderRadius: "25px",
            border: "2px solid #73AD21",
          }}
        >
          <table>
            {this.itemChunks(this.props.wordInfo.ownWords).map(
              (words, index) => (
                <tr key={index}>
                  {words.map((word) => (
                    <td style={{ width: "50px" }} key={word}>
                      {word}
                    </td>
                  ))}
                </tr>
              )
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default connect(
  mapWordStateToProps,
  mapWordDispatchToProps
)(WordOwnList);
