import React, { Component } from "react";
import { mapWordDispatchToProps } from "../../store/actions";
import { mapWordStateToProps } from "../../store/store";
import { connect } from "react-redux";
import UserList from "./UserList";
import { Button } from "reactstrap";

class WordWait extends Component {
  constructor(props) {
    super(props);
    this.leaveGame = this.leaveGame.bind(this);
  }

  leaveGame() {
    this.props.leaveWordGame(this.props.wordInfo.game.id);
  }

  render() {
    return (
      <div>
        <h4>
          Připojen ke hře <b>Id:{this.props.wordInfo.game.id}</b>
        </h4>
        <nav className="navbar navbar-expand-lg">
          <Button onClick={() => this.leaveGame()}>Odejít</Button>
        </nav>
        <br />

        <hr />
        <UserList />
      </div>
    );
  }
}

export default connect(mapWordStateToProps, mapWordDispatchToProps)(WordWait);
