import userReducer from "./userReducer";
import pexesoReducer from "./pexesoReducer";
import callbacks from "./callbacks";
import { signalMiddleware } from "redux-signalr";
import connection from "./connection";
import wordReducer from "./wordReducer";

export function rootReducer(state = {}, action) {
  // always return a new object for the root state
  return {
    // the value of `state.todos` is whatever the todos reducer returns
    user: userReducer(state.user, action),
    pexeso: pexesoReducer(state.pexeso, action),
    word: wordReducer(state.word, action),
  };
}

export const signal = signalMiddleware({
  callbacks,
  connection,
});
